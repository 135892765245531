/**
 *
 * Asynchronously loads the component for ViewOrdersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ViewOrdersPage = lazyLoad(
  () => import('./index'),
  module => module.ViewOrdersPage,
);
