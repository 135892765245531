import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import './index.css';
import App from 'app';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { DialogProvider } from 'muibox';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <HelmetProvider>
        <SnackbarProvider maxSnack={3}>
          <DialogProvider>
            <App />
          </DialogProvider>
        </SnackbarProvider>
      </HelmetProvider>
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
