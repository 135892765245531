/**
 *
 * Asynchronously loads the component for AgentsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AgentDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.AgentDetailsPage,
);
