import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

/* * {
  outline: 1px solid #f00 !important;
} */
*, *:before, *:after {
  box-sizing: inherit;
}
  html,
  body {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }


  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  body.fontLoaded {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;
  }
  /* Works on Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
}

/* Works on Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 12px;
} */

/* *::-webkit-scrollbar-track {
  background: orange;
} */

/* *::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: 3px solid orange;
} */ */

  p,
  label {
   
    line-height: 1.5em;
  }
  .green{
    color:#28882C !important;
  }
  .red{
    color:#DE4646 !important;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
    &::placeholder {
    opacity: 1 !important;
    }
    &::-webkit-input-placeholder {
     
     opacity: 1; /*Change the opacity between 0 and 1*/
}
:-webkit-input-placeholder {
     
     opacity: 1; /*Change the opacity between 0 and 1*/
}
  }

  .MuiDataGrid-columnSeparator{
    visibility: hidden;
  }
  .MuiDataGrid-columnHeaders{
    border-bottom:none !important;
    background: #FFF2DE;
    color: #222222;
    font-weight: 500;
    font-size: 0.8125rem;
    /* line-height: 2.5rem !important;
    max-height: 2.5rem !important;
    min-height: 2.5rem !important; */
  }
  .MuiDataGrid-virtualScroller {
    /* margin-top:2.5rem !important; */
  }
  .MuiCircularProgress-colorPrimary{
    color:#6251ad !important;
    z-index:2;
  }
  .MuiCheckbox-colorPrimary{
    color:#666666 !important;
  }
  .ch-6{
    flex-basis: 6ch !important;
      min-width: 6ch !important;
  }
  .ch-10{
    flex-basis: 10ch;
      min-width: 10ch;
  }
  .MuiDataGrid-cell--editable > div {
    padding: 5px;
    line-height: 1.2rem;
    background: #F0EEF5;
    width: 100%;
    margin: 5px;
    border-radius: 6px;
    input{
      padding: 0px;
    }
  }
  .MuiDataGrid-cell.MuiDataGrid-cell--editing{
    padding: 0 10px !important;
    background-color:inherit !important;
  }

  .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within{
    outline:solid #6251ad 1px !important;
  }
  .MuiDataGrid-cell{
    color: #333;
    font-size: 0.8125rem;
  }

  .MuiFormHelperText-root.Mui-error.MuiFormHelperText-contained{
   color:#ff7979 !important;
  }
  /* .search-lookup{
    display:flex;
    padding:.5rem 1rem;
    & > div{
      flex-basis: 10ch;
      min-width: 10ch;
    text-align: right;

     p:nth-child(1){
      font-size: 13px;
line-height: 18px;
color: #333333;
margin:0;
     }
     p:nth-child(2){
      font-size: 11px;
      color: #757575;
      margin: 0.5rem 0;
     }
    }
    .title{
      flex-basis: 60ch;
      text-align: left;
    }
    
  }*/
  .create_order.MuiAutocomplete-popper{
  /* padding: 20px; */
  /* margin: 20px; */
  width: 100% !important;
  max-width:600px;
}


@media screen and (max-width: 630px) {
  .MuiAutocomplete-popper {
    padding: 0 20px; 
  }
}

`;
