/**
 *
 * Asynchronously loads the component for RetailersPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RetailersPage = lazyLoad(
    () => import('./index'),
    module => module.RetailersPage,
);
