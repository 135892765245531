/**
 *
 * Asynchronously loads the component for OrderDetailsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const OrderDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.OrderDetailsPage,
);
