/**
 *
 * Asynchronously loads the component for AgentsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EditProductDetailsPage = lazyLoad(
    () => import('./index'),
    module => module.EditProductDetailsPage,
);
