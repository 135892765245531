/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { Routes, Route, HashRouter } from 'react-router-dom';

import { GlobalStyle } from 'styles/global-styles';

import { HomePage } from './pages/HomePage/Loadable';

import { CreateOrderProductsListPage } from './pages/CreateOrderProductsListPage/Loadable';

import { RegistrationPage } from './pages/RegistrationPage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import { ViewOrdersPage } from './pages/ViewOrdersPage/Loadable';
import { OrderDetailsPage } from './pages/OrderDetailsPage/Loadable';
// import { useTranslation } from 'react-i18next';
import { SuppliersPage } from './pages/SuppliersPage/Loadable';
import { RetailersPage } from './pages/RetailersPage/Loadable';
import { AgentsPage } from './pages/AgentsPage/Loadable';
// import { ConfirmProvider } from 'material-ui-confirm';

import { ForgotPasswordPage } from './pages/ForgotPasswordPage/Loadable';
import { UpdateProfilePage } from './pages/UpdateProfilePage/Loadable';
import { AgentDetailsPage } from './pages/AgentDetailsPage/Loadable';
import { SettingsPage } from './pages/SettingsPage/Loadable';
import { ProductsPage } from './pages/ProductsPage/Loadable';

import { ProductDetailsPage } from './pages/ProductDetailsPage/Loadable';
import { EditProductDetailsPage } from './pages/EditProductDetailsPage/Loadable';

import { ProductsWithImagesPage } from './pages/ProductsWithImagesPage/Loadable';

import { UpdateStorePage } from './pages/UpdateStorePage/Loadable';

import { CartPage } from './pages/CartPage/Loadable';
export default function App() {
    // const { i18n } = useTranslation();
    // if (window.location.protocol === 'https:')
    //   window.location.replace(`http://${window.location.host}`);
    return (
        <HashRouter>
            <Helmet
                titleTemplate="%s - Orderplus"
                defaultTitle="Orderplus"
                // htmlAttributes={{ lang: i18n.language }}
            >
                <meta name="description" content="Home - Orderplus" />
            </Helmet>

            <Routes>
                <Route path="/" element={<CreateOrderProductsListPage />} />
                <Route path="/create-order" element={<HomePage />} />
                <Route path="/cart" element={<CartPage />} />

                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegistrationPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/profile-edit" element={<UpdateProfilePage />} />

                <Route path="/product-detail-page/:productGuid" element={<ProductDetailsPage />} />

                <Route path="/edit-product-detail-page/:productGuid" element={<EditProductDetailsPage />} />

                <Route path="/view-orders" element={<ViewOrdersPage />} />
                <Route path="/order-details" element={<OrderDetailsPage />} />
                <Route path="/suppliers" element={<SuppliersPage />} />
                <Route path="/agents" element={<AgentsPage />} />
                <Route path="/agent-details/:agentId" element={<AgentDetailsPage />} />
                <Route path="/customers" element={<RetailersPage />} />
                <Route path="/products" element={<ProductsWithImagesPage />} />
                <Route path="/products-list" element={<ProductsWithImagesPage />} />

                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/store-edit" element={<UpdateStorePage />} />
            </Routes>

            <GlobalStyle />
        </HashRouter>
    );
}
